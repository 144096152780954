import React from 'react';
import { StitchAuthProvider, useStitchAuth } from './realms';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import Stats from './Pages/Stats';
import Orders from './Pages/Orders';
import Login from './Pages/Login';
import PasswordReset from './Pages/PasswordReset';
import 'semantic-ui-css/semantic.min.css';

function App() {
  return (
    <StitchAuthProvider>
      <Router>
        <Switch>
          <Route path="/passwordreset">
            <PasswordReset />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <PrivateRoute path="/orders">
            <Orders />
          </PrivateRoute>
          <PrivateRoute path="/stats">
            <Stats />
          </PrivateRoute>

          <Route path="*">
            <Redirect to={{ pathname: '/login' }} />
          </Route>
        </Switch>
      </Router>
    </StitchAuthProvider>
  );
}

function PrivateRoute({ children, ...rest }) {
  const { isLoggedIn } = useStitchAuth();

  if (isLoggedIn) {
    return <Route {...rest} render={() => children} />;
  } else {
    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )}
      />
    );
  }
}

export default App;
