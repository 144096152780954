import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useStitchAuth } from '../realms';

const TopBar = styled.div`
  display: flex;
`;

export default function AppBar(props) {
  let location = useLocation();
  const { actions } = useStitchAuth();

  const menuItems = [
    {
      to: '/orders',
      title: 'Orders',
    },
    {
      to: '/stats',
      title: 'Stats',
    },
  ];

  return (
    <div className="appbar box">
      <TopBar>
        {menuItems.map((menuItem) => (
          <div
            key={menuItem.to}
            style={{
              marginLeft:
                menuItem.title === 'Orders' ? 'auto' : '1em',
            }}
          >
            <Link
              to={menuItem.to}
              style={{
                color: 'black',
                height: '30px',
                lineHeight: '30px',
              }}
            >
              {menuItem.to === location.pathname ? (
                <b>{menuItem.title}</b>
              ) : (
                <>{menuItem.title}</>
              )}
            </Link>
          </div>
        ))}
        <div style={{ marginLeft: '1em' }}>
          <Link
            to="/login"
            onClick={actions.handleLogout}
            style={{
              color: 'black',
              height: '30px',
              lineHeight: '30px',
            }}
          >
            Logout
          </Link>
        </div>
      </TopBar>
    </div>
  );
}
