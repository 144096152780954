import React, { useState, useEffect } from 'react';
import { useStitchAuth } from '../realms';
import {
  Container,
  Dimmer,
  Header,
  Loader,
  Icon,
  Divider,
  Table,
} from 'semantic-ui-react';
import AppBar from '../Components/AppBar';

const Stats = (props) => {
  const { currentUser } = useStitchAuth();
  const [orgs, setOrgs] = useState([]);
  const [months, setMonths] = useState([]);
  const [allMembers, setAllMembers] = useState([]);
  const [numMonths, setNumMonths] = useState(6);
  const [loading, setLoading] = useState(false);

  const members = currentUser
    .mongoClient('mongodb-atlas')
    .db('test')
    .collection('members');

  const fetchMembers = async () => {
    if (currentUser) {
      setLoading(true);
      const allMembers = await members.find(
        {},
        {
          projection: {
            owner_id: 1,
            bodyWeightData: 1,
            systolicBPData: 1,
            bloodSugarData: 1,
            ecgData: 1,
            enrollmentDate: 1,
          },
        },
      );
      setAllMembers(allMembers);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const orgs = [];
    const today = new Date();
    // start numMonths months ago
    let curr = new Date(
      new Date().setMonth(today.getMonth() - numMonths + 1),
    );
    const months = [];

    const totalMonths = ['total'];
    for (let oI = 0; oI < numMonths; oI++) {
      totalMonths.push({ active: 0, total: 0 });
    }
    orgs.push(totalMonths);

    for (let i = 0; i < numMonths; i++) {
      const currCopy = new Date(curr);
      months.push(currCopy);

      for (let mI = 0; mI < allMembers.length; mI++) {
        const member = allMembers[mI];
        let orgI = -1;
        for (let j = 0; j < orgs.length; j++) {
          if (orgs[j][0] === member.owner_id) {
            orgI = j;
          }
        }
        if (orgI < 0) {
          // seeding org row
          const orgMonths = [member.owner_id];
          for (let oI = 0; oI < numMonths; oI++) {
            orgMonths.push({ active: 0, total: 0 });
          }
          orgs.push(orgMonths);
          orgI = orgs.length - 1;
        }

        const numMeasureThisMonth =
          (member.bodyWeightData || []).filter(
            (x) =>
              new Date(x.time).toISOString().slice(0, 7) ===
              currCopy.toISOString().slice(0, 7),
          ).length +
          (member.systolicBPData || []).filter(
            (x) =>
              new Date(x.time).toISOString().slice(0, 7) ===
              currCopy.toISOString().slice(0, 7),
          ).length +
          (member.bloodSugarData || []).filter(
            (x) =>
              new Date(x.time).toISOString().slice(0, 7) ===
              currCopy.toISOString().slice(0, 7),
          ).length +
          (member.ecgData || []).filter(
            (x) =>
              new Date(x.time).toISOString().slice(0, 7) ===
              currCopy.toISOString().slice(0, 7),
          ).length;

        if (numMeasureThisMonth > 0) {
          orgs[orgI][i + 1].active += 1;
          orgs[0][i + 1].active += 1; // orgs[0] represents total
        }
        const enrDate = new Date(member.enrollmentDate);
        if (
          enrDate.getFullYear() < currCopy.getFullYear() ||
          (enrDate.getFullYear() === currCopy.getFullYear() &&
            enrDate.getMonth() <= currCopy.getMonth())
        ) {
          orgs[orgI][i + 1].total += 1;
          orgs[0][i + 1].total += 1; // orgs[0] represents total
        }
      }

      curr = new Date(curr.setMonth(curr.getMonth() + 1));
    }

    orgs.sort((a, b) => b[numMonths].active - a[numMonths].active);
    setOrgs(orgs);
    setMonths(months);
  }, [allMembers, numMonths]);

  if (loading) {
    return (
      <Container style={{ overflowX: 'scroll' }}>
        <AppBar />
        <Header>Orma Statistics</Header>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
      </Container>
    );
  } else {
    return (
      <Container style={{ overflowX: 'scroll' }}>
        <AppBar />
        <Header>Orma Statistics</Header>
        <Icon
          name="caret left"
          onClick={() => {
            setNumMonths(numMonths - 1);
          }}
        />{' '}
        numMonths: {numMonths}{' '}
        <Icon
          name="caret right"
          onClick={() => {
            setNumMonths(numMonths + 1);
          }}
        />
        <Divider />
        <Table size="small" basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Org Name</Table.HeaderCell>
              {months.map((m, i) => (
                <Table.HeaderCell key={i}>
                  {m.toISOString().slice(0, 7)}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {orgs.map((org, i) => (
              <Table.Row key={i}>
                {org.map((monthEl, j) => (
                  <Table.Cell key={j}>
                    {typeof monthEl === 'string' // the first el in org array will be the org id.
                      ? monthEl
                      : `${monthEl.active} / ${monthEl.total}`}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Container>
    );
  }
};

export default Stats;
