import React, { useState } from 'react';
import { useStitchAuth } from '../realms';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PasswordResetForm = (props) => {
  const [password, setPassword] = useState('');
  const [formError, setFormError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { actions } = useStitchAuth();

  let query = useQuery();
  let token = query.get('token');
  let tokenId = query.get('tokenId');

  const handleSubmit = (event) => {
    actions
      .handleResetPassword(token, tokenId, password)
      .then(() => {
        // history.replace({ pathname: '/login' });
        setSuccess(true);
      })
      .catch(() => {
        setFormError(true);
      });
  };

  if (success) {
    return (
      <>
        <h3>Password Reset Success!</h3>
        Please visit the{' '}
        <a href="/login" target="_blank">
          login page
        </a>{' '}
        and re-login with your new password.
      </>
    );
  } else {
    return (
      <>
        <h3>Reset Password</h3>
        {formError && (
          <>
            Invalid Password <br />
            Password should have at least 6 characters. <br />
            <br />
          </>
        )}
        <input
          placeholder="New Password"
          type="password"
          value={password}
          onChange={(event) =>
            setPassword(event.target.value.replace(/\s/g, ''))
          }
        />
        <button onClick={handleSubmit}>Change Password</button>
      </>
    );
  }
};

export default PasswordResetForm;
