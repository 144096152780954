import React from 'react';
import PropTypes from 'prop-types';
import * as Realm from 'realm-web';

const APP_ID = 'admin-cwrrw';
const app = new Realm.App({ id: APP_ID });

export function getLoggedInUser() {
  // Check if there is currently a logged in user
  return app.currentUser;
}

export function logoutCurrentUser() {
  // Logout the currently logged in user
  return app.currentUser.logOut();
}

export async function confirmEmail(token, tokenId) {
  return await app.emailPasswordAuth.confirmUser(token, tokenId);
}

export async function loginUser(email, password) {
  const credentials = Realm.Credentials.emailPassword(
    email,
    password,
  );
  //const credential = new UserPasswordCredential(email, password);
  try {
    const user = await app.logIn(credentials);
    //assert(user.id===app.currentUser.id);
    return user;
  } catch (err) {
    console.error('Failed to log in', err);
  }
}

export async function sendResetPasswordEmail(email) {
  return app.emailPasswordAuth.sendResetPasswordEmail(email);
}

export async function resetPassword(token, tokenId, newPassword) {
  return app.emailPasswordAuth.resetPassword(
    token,
    tokenId,
    newPassword,
  );
}

//////////////////////////////////////////
//////////////////////////////////////////
//////////////////////////////////////////

const StitchAuthContext = React.createContext();

// Create a React Hook that lets us get data from our auth context
export function useStitchAuth() {
  const context = React.useContext(StitchAuthContext);
  if (!context) {
    throw new Error(
      `useStitchAuth must be used within a StitchAuthProvider`,
    );
  }
  return context;
}

// Create a component that controls auth state and exposes it via
// the React Context we created.
export function StitchAuthProvider(props) {
  const [authState, setAuthState] = React.useState({
    isLoggedIn: getLoggedInUser() !== null,
    currentUser: getLoggedInUser(),
    db: {
      registry: null,
    },
  });

  // We useMemo to improve performance by eliminating some re-renders
  const authInfo = React.useMemo(() => {
    // Authentication Actions
    const handleLogout = async () => {
      const { isLoggedIn } = authState;
      if (isLoggedIn) {
        await logoutCurrentUser();
        setAuthState({
          ...authState,
          isLoggedIn: false,
          currentUser: null,
          userCustomData: null,
          otherUsers: [],
        });
      } else {
        console.log(`can't handleLogout when no user is logged in`);
      }
    };

    const handleConfirmEmail = async (token, tokenId) => {
      confirmEmail(token, tokenId);
    };

    const handleEmailPasswordLogin = async (email, password) => {
      const loggedInUser = await loginUser(email, password);
      setAuthState({
        ...authState,
        isLoggedIn: true,
        currentUser: loggedInUser,
        db: {
          registry: loggedInUser
            .mongoClient('mongodb-atlas')
            .db('test')
            .collection('registry'),
        },
      });
    };

    const handleResetPasswordSend = async (email) => {
      return sendResetPasswordEmail(email);
    };

    const handleResetPassword = async (
      token,
      tokenId,
      newPassword,
    ) => {
      return resetPassword(token, tokenId, newPassword);
    };

    const { isLoggedIn, currentUser, db } = authState;
    const value = {
      isLoggedIn,
      currentUser,
      db,
      actions: {
        handleLogout,
        handleConfirmEmail,
        handleEmailPasswordLogin,
        handleResetPasswordSend,
        handleResetPassword,
      },
    };
    return value;
  }, [authState]);
  return (
    <StitchAuthContext.Provider value={authInfo}>
      {props.children}
    </StitchAuthContext.Provider>
  );
}
StitchAuthProvider.propTypes = {
  children: PropTypes.element,
};
