import React, { useState, useEffect } from 'react';
import { useStitchAuth } from '../realms';
import { useHistory } from 'react-router-dom';

const Login = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState(null);
  const { isLoggedIn, actions } = useStitchAuth();

  let history = useHistory();

  useEffect(() => {
    if (isLoggedIn) {
      history.replace({
        pathname: '/orders',
      });
    }
  }, [history, isLoggedIn]);

  const handleLogin = (event) => {
    actions.handleEmailPasswordLogin(email, password).catch(() => {
      setMessage(
        <>
          Invalid Username or Password. <br />
          Please check your username/password again. <br />
          <br />
        </>,
      );
    });
  };

  return (
    <>
      Login here: <br />
      <br />
      {message}
      <input
        placeholder="E-mail address"
        value={email}
        onChange={(event) =>
          setEmail(event.target.value.replace(/\s/g, ''))
        }
      />
      <input
        placeholder="Password"
        type="password"
        value={password}
        onChange={(event) =>
          setPassword(event.target.value.replace(/\s/g, ''))
        }
      />
      <button onClick={handleLogin}>Log-in</button>
      <button
        onClick={() => {
          actions
            .handleResetPasswordSend(email)
            .then(() => {
              setMessage(
                <>
                  Password reset link sent to your email. <br />
                  <br />
                </>,
              );
            })
            .catch((err) => {
              console.log(err);
              setMessage(
                <>
                  Error: Please enter your valid email. <br />
                  <br />
                </>,
              );
            });
        }}
      >
        Email Password Reset Link
      </button>
    </>
  );
};

export default Login;
