import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useStitchAuth } from '../realms';
import {
  Button,
  Card,
  Container,
  Grid,
  Header,
  Divider,
} from 'semantic-ui-react';

import AppBar from '../Components/AppBar';

const Orders = (props) => {
  const { currentUser } = useStitchAuth();
  const [requestedDevices, setRequestedDevices] = useState([]);
  const [orderedCuffs, setOrderedCuffs] = useState([]);

  const registry = currentUser
    .mongoClient('mongodb-atlas')
    .db('test')
    .collection('registry');

  const members = currentUser
    .mongoClient('mongodb-atlas')
    .db('test')
    .collection('members');

  const fetchDevices = async () => {
    if (currentUser) {
      const requested = (
        await registry.find({
          device: { $in: ['bpCuff', 'ZCPO', 'MLPF', 'ACKM'] },
          status: 'requested',
        })
      ).sort(
        (a, b) => new Date(a.requestDate) - new Date(b.requestDate),
      );
      const ordered = (
        await registry.find({
          device: { $in: ['bpCuff', 'ZCPO', 'MLPF', 'ACKM'] },
          status: 'ordered',
        })
      ).sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));
      setRequestedDevices(requested);
      setOrderedCuffs(ordered);
    }
  };

  useEffect(() => {
    fetchDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const DeviceInfoToDisplay = ({ device, i, isRequested }) => {
    const [address, setAddress] = useState({
      address1: device.address1,
      address2: device.address2,
      city: device.city,
      state: device.state,
      zipcode: device.zipcode,
    });

    const placeOrderButton = (
      <Button
        primary
        onClick={async () => {
          await registry.updateOne(
            {
              memberId: device.memberId,
              device: device.device,
              status: 'requested',
            },
            {
              $set: {
                status: 'ordered',
                orderDate: new Date().toISOString(),
              },
            },
          );
          fetchDevices();
        }}
      >
        Click, if order placed
      </Button>
    );

    const revertOrderButton = (
      <Button
        onClick={async () => {
          await registry.updateOne(
            {
              memberId: device.memberId,
              device: device.device,
              status: 'ordered',
            },
            {
              $set: {
                status: 'requested',
                orderDate: null,
              },
            },
          );
          fetchDevices();
        }}
      >
        Click, if order not placed
      </Button>
    );

    const getAmazonLink = () => {
      if (device.device === 'ZCPO') {
        return 'https://www.amazon.com/Zacurate-Fingertip-Oximeter-Saturation-Batteries/dp/B07PQ8WTC4';
      } else if (device.device === 'MLPF') {
        return 'https://www.amazon.com/Microlife-PF-100-Meter-Spirometry/dp/B000BH8TUA';
      } else if (device.device === 'bpCuff') {
        if (device.size === 'xs') {
          return 'https://www.amazon.com/Blood-Pressure-Monitor-Replacement-Small/dp/B07HB7C5JW';
        } else {
          return 'https://www.amazon.com/Blood-Pressure-Upper-Strap-inches/dp/B07RW8DXZ7';
        }
      } else {
        return '';
      }
    };

    return (
      <Card key={i}>
        <Card.Content>
          <Header>
            {isRequested
              ? `requested on
                ${moment(
                  device.requestDate || device.startDate,
                ).format('MMMM Do YYYY, h:mma')}`
              : `ordered on
            ${moment(device.orderDate).format(
              'MMMM Do YYYY, h:mma',
            )}`}
            <br />
            {device.device}, {device.size && `size: ${device.size},`}{' '}
            {isRequested ? (
              <a
                href={getAmazonLink()}
                target="_blank"
                rel="noreferrer"
              >
                order here
              </a>
            ) : (
              <a
                href={
                  'https://www.amazon.com/gp/css/order-history?ref_=abn_bnav_ya_ap_orders'
                }
                target="_blank"
                rel="noreferrer"
              >
                view orders
              </a>
            )}
          </Header>
          <p>{device.name}</p>
          <p>{device.phone}</p>
          <p>
            {address.address1}
            <br />
            {address.address2}
            <br />
            {address.city}
            <br />
            {address.state}
            <br />
            {address.zipcode}
          </p>
          <Button
            onClick={() => {
              members
                .findOne(
                  { _id: device.memberId },
                  { projection: { address: 1 } },
                )
                .then((res) => {
                  setAddress({
                    address1: res.address.line1,
                    address2: res.address.line2,
                    city: res.address.city,
                    state: res.address.state,
                    zipcode: res.address.zipCode,
                  });
                });
            }}
          >
            Get Latest Address
          </Button>
        </Card.Content>
        <Card.Content extra>
          <p>{isRequested ? placeOrderButton : revertOrderButton}</p>
        </Card.Content>
      </Card>
    );
  };

  return (
    <>
      <Container>
        <AppBar />
        <Grid>
          <Grid.Row columns={1} style={{ marginTop: '1em' }}>
            <Grid.Column>
              <Header>Manual Order Dashboard</Header>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row columns={1}>
            <Grid.Column>
              <Card.Group itemsPerRow={5}>
                {requestedDevices.map((device, i) => (
                  <DeviceInfoToDisplay
                    key={i}
                    device={device}
                    i={i}
                    isRequested={true}
                  />
                ))}
              </Card.Group>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row columns={1}>
            <Grid.Column>
              <Card.Group itemsPerRow={5}>
                {orderedCuffs.map((device, i) => (
                  <DeviceInfoToDisplay
                    key={i}
                    device={device}
                    i={i}
                    isRequested={false}
                  />
                ))}
              </Card.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </>
  );
};

export default Orders;
